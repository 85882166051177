export default {
  colors: {
    background: '#f5f5f5',
    text: '#000000',
    primary: '#fff',
    secondary: '#0050ff',
    tertiary: '#f3f3f3',
    highlight: '#5b8bf7',
    textBody: '#232323',
  },
  fonts: {
    body:
      '-apple-system, Helvetica Neue, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
  },
  sizes: {
    maxWidth: '1120px',
    maxWidthCentered: '650px',
  },
  responsive: {
    small: '35em',
    medium: '48em',
    large: '64em',
  },
}
